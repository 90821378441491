import React, { Fragment, Component } from 'react'
import { Link, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { Media } from '@renderbus/common/theme'
import {
  Wrapper,
  Wrapper as SearchWrapper,
  Breadcrumb,
  Icon,
  IconType,
  CmsPostContainer,
  CmsPostTitle,
  CmsPostMsg,
  CmsLinkToPost,
  SEO,
  SearchBox,
  QaInstantSearchResult,
  WordCloud,
  Layout,
} from '@renderbus/common/components'
import { color } from '@renderbus/common/theme'

import Sidebar from '../components/sidebar'
import AdBanner from '../components/ad-banner'
import {
  SectionContainer,
  SectionWrapper,
  SearchWrapper as SearchWrapperContainer,
  SearchResultList,
} from '../style/common'
import SearchEngine from '../components/search/search-engine'

const QaSectionContainer = styled(SectionContainer)`
  width: 60%;
  margin-right: 100px;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    margin-right: 0px;
  }
`
const SidebarWrapper = styled(Wrapper)`
  width: 30%;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`
class Post extends Component {
  render() {
    const { location, pageContext, data } = this.props
    const {
      tags,
      question,
      date,
      answer,
      tinyMceContent,
      previousSlug,
      nextSlug,
      previousQuestion,
      nextQuestion,
      slug,
      seoTitle,
      keywords,
      description,
      topThreeShare,
    } = pageContext
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO title={seoTitle} description={description || ''} keywords={keywords || ''} />
        <WordCloud wordCloudArray={data.term.nodes} />
        <AdBanner />
        <Wrapper>
          <SectionWrapper>
            <QaSectionContainer>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item href='/'>渲染常见问题大全</Breadcrumb.Item>
                {tags ? (
                  <Breadcrumb.Item href={`/${tags[0].slug}/`}>{tags[0].name}</Breadcrumb.Item>
                ) : null}
                <Breadcrumb.Item href={`/${slug}/`}>{question}</Breadcrumb.Item>
              </Breadcrumb>
              <CmsPostTitle>{question}</CmsPostTitle>
              <CmsPostMsg>
                <div>
                  <Icon type={IconType.Clock} />
                  <span>{date}</span>
                </div>
                <div>
                  {tags &&
                    tags.map((tag, index) => (
                      <Link
                        key={index}
                        to={`/${tag.slug}/`}
                        style={{ color: color.primary, marginRight: '10px' }}
                      >
                        {tag.name}
                      </Link>
                    ))}
                </div>
              </CmsPostMsg>
              <CmsPostContainer>
                <ReactMarkdown
                  source={answer || tinyMceContent}
                  escapeHtml={false}
                  linkTarget='_blank'
                />
              </CmsPostContainer>
              <CmsLinkToPost>
                {previousSlug ? (
                  <Fragment>
                    <span>下一篇：</span>
                    <Link to={`${previousSlug}/`}>{previousQuestion}</Link>
                  </Fragment>
                ) : null}
              </CmsLinkToPost>
              <CmsLinkToPost>
                {nextSlug ? (
                  <Fragment>
                    <span>上一篇：</span>
                    <Link to={`${nextSlug}/`}>{nextQuestion}</Link>
                  </Fragment>
                ) : null}
              </CmsLinkToPost>
            </QaSectionContainer>
            <SidebarWrapper>
              <SearchWrapper>
                <SearchEngine
                  type='qa'
                  render={({ type, query, result, handleChange, handleSearch }) => (
                    <SearchWrapperContainer style={{ maxWidth: '1000px', margin: '0 auto' }}>
                      <div>
                        <SearchBox
                          type={type}
                          searchContent={query}
                          onSearch={handleSearch}
                          onChange={handleChange}
                        />
                      </div>
                      <SearchResultList isShow={query}>
                        <QaInstantSearchResult result={result} searchContent={query} />
                      </SearchResultList>
                    </SearchWrapperContainer>
                  )}
                />
              </SearchWrapper>
              <Sidebar allList={data.allList.edges} tags={tags} />
            </SidebarWrapper>
          </SectionWrapper>
        </Wrapper>
      </Layout>
    )
  }
}

export default Post

export const CloudTermQuery = graphql`
  query {
    term: allContentfulQaTerm {
      nodes {
        name
        slug
      }
    }
    allList: allContentfulQa(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          createdAt(formatString: "YYYY-MM-DD kk:mm:ss")
          question
          slug
          tags {
            name
            slug
          }
        }
      }
    }
  }
`
