import styled from 'styled-components'
import { Media, typography, spacing } from '@renderbus/common/theme'
import { Flex, Anchor } from '@renderbus/common/components'

export const SectionContainer = styled.div`
  max-width: 100%;
  flex: 1;
  ${Media.lessThan(Media.large)} {
    margin-right: 0;
  }
`
export const SectionWrapper = styled(Flex)`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  align-items: flex-start;
  background-color: white;
  justify-content: flex-start;
`
export const BannerContainer = styled(Flex)`
  height: 360px;
  background: #191919;
  ${Media.lessThan(Media.large)} {
    align-items: flex-start;
  }
`
export const WordContainer = styled.div`
  margin: 70px ${spacing.base} 0;
  overflow-y: hidden;
  height: 18em;
`
export const Word = styled(Anchor)`
  display: inline-block;
  white-space: nowrap;
  margin: 0 5px;
  cursor: pointer;
  color: #349fd5;
  line-height: ${typography.text};
  font-size: ${typography.text};
`

export const SearchWrapper = styled.div`
  position: relative;
  ${Media.lessThan(Media.small)} {
    margin-bottom: ${spacing.small};
  }
`

export const SearchResultList = styled.div`
  display: ${props => (props.isShow ? 'div' : 'none')};
  position: absolute;
  top: 55px;
  right: 2px;
  z-index: 10;
`
