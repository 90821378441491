import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { darken } from 'polished'
import { Divider, Flex } from '@renderbus/common/components'
import { textEllipsis, color, typography, spacing } from '@renderbus/common/theme'

const ItemContainer = styled(Flex)`
  flex-direction: column;
  align-content: flex-start;
`
const Title = styled.p`
  width: 100%;
  ${textEllipsis}
  a {
    color: ${darken(0.4, color.text)};
  }
  :hover a {
    color: ${color.panel};
  }
  font-size: ${typography.textSmall};
  margin-bottom: ${spacing.small};
`
const Date = styled.p`
  width: 100%;
  color: ${color.prompt};
  font-size: ${typography.textSmall};
`

class SidebarItem extends Component {
  render() {
    const { date, question, slug } = this.props
    return (
      <div>
        <ItemContainer>
          <Title>
            <Link to={`/${slug}/`} target='_blank'>
              {question}
            </Link>
          </Title>
          <Date>{date}</Date>
        </ItemContainer>
        <Divider />
      </div>
    )
  }
}
export default SidebarItem
