import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { SideBarTitle } from '@renderbus/common/components'
import { hideLGDown } from '@renderbus/common/theme'
import SidebarItem from './item'

const SidebarWrapper = styled.div`
  ${hideLGDown}
`
class Sidebar extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            recentList: allContentfulQa(limit: 5, sort: { fields: createdAt, order: DESC }) {
              edges {
                node {
                  createdAt(formatString: "YYYY-MM-DD kk:mm:ss")
                  slug
                  question
                }
              }
            }
          }
        `}
        render={({ recentList: { edges } }) => {
          let relatedArticlesList = []
          this.props.allList &&
            this.props.allList.forEach(({ node }) => {
              node.tags &&
                node.tags.forEach(({ name }) => {
                  this.props.tags &&
                    this.props.tags.forEach(item => {
                      if (name === item.name) relatedArticlesList.push(node)
                    })
                })
            })
          function unique(arr) {
            const res = new Map()
            return arr.filter(a => !res.has(a.slug) && res.set(a.slug, 1))
          }
          relatedArticlesList = unique(relatedArticlesList).slice(0, 11)
          return (
            <SidebarWrapper>
              {relatedArticlesList.length > 0 && <SideBarTitle>相关问题</SideBarTitle>}
              {relatedArticlesList.map(({ updatedAt, slug, question }, index) => (
                <SidebarItem key={index} date={updatedAt} slug={slug} question={question} />
              ))}
              <SideBarTitle>最新问题</SideBarTitle>
              {edges.map(({ node }, index) => (
                <SidebarItem
                  key={index}
                  date={node.createdAt}
                  slug={node.slug}
                  question={node.question}
                />
              ))}
            </SidebarWrapper>
          )
        }}
      />
    )
  }
}
export default Sidebar
